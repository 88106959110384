import React from "react";
import { Image } from "../../../common/shared-components/images";
import {
  Container,
  Content,
  Header,
  ImageHolder,
  Text,
  TextHolder,
} from "./styles";

import { useIntl } from "gatsby-plugin-react-intl";
import i18n from "../i18n";

const KeyboardMac = () => {
  const intl = useIntl();
  return (
    <Container>
      <Content>
        <TextHolder>
          <Header>{intl.formatMessage(i18n.keyboard.title)}</Header>
          <Text>
            {intl.formatMessage(i18n.keyboard.content, {
              color: (str) => <span style={{ color: "#5CE1E6" }}>{str} </span>,
            })}
          </Text>
        </TextHolder>
        <ImageHolder>
          <Image source="keyboardMac.jpeg" alt="Keyboard macbook" />
        </ImageHolder>
      </Content>
    </Container>
  );
};

export default KeyboardMac;
