import React from "react";

import BulletText from "../../../common/bullet-list";

import { useIntl } from "gatsby-plugin-react-intl";
import i18n from "../i18n";

const MacbookBullet = () => {
  const intl = useIntl();
  return (
    <BulletText
      headline={intl.formatMessage(i18n.diagonose.title)}
      text1={intl.formatMessage(i18n.diagonose.text1)}
      text2={intl.formatMessage(i18n.diagonose.text2)}
      text3={intl.formatMessage(i18n.diagonose.text3)}
    />
  );
};

export default MacbookBullet;
