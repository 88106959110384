import React from "react"
import { Image } from "../../../common/shared-components/images"

import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../i18n"
import { Container, Header, ImgHolder, Text, ContentHolder } from "./styles"

const ShotCircuitMac = () => {
  const intl = useIntl()
  return (
    <Container>
      <ContentHolder>
        <ImgHolder>
          <Image source="shot-circuit.jpg" alt="shot circuit" />
        </ImgHolder>
        <Header>{intl.formatMessage(i18n.shotCircuit.title)} </Header>
        <Text>{intl.formatMessage(i18n.shotCircuit.content)}</Text>
      </ContentHolder>
    </Container>
  )
}

export default ShotCircuitMac
