import React from "react"
import { Image } from "../../../common/shared-components/images"

import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../i18n"
import { Container, Header, ImgHolder, Text } from "./styles"

const DisFailMac = () => {
  const intl = useIntl()
  return (
    <Container>
      <ImgHolder>
        <Image source="disFailMac.png" alt="Display Failure" />
      </ImgHolder>
      <Header>{intl.formatMessage(i18n.displayFail.title)} </Header>
      <Text>{intl.formatMessage(i18n.displayFail.text1)}</Text>
      <Text>{intl.formatMessage(i18n.displayFail.text2)}</Text>
    </Container>
  )
}

export default DisFailMac
