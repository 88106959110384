import React from "react"
import { Image } from "../../../common/shared-components/images"
import {
  Container,
  Content,
  Header,
  ImageHolder,
  Text,
  TextHolder,
} from "./styles"

import { useIntl, Link } from "gatsby-plugin-react-intl"
import i18n from "../i18n"

const WaterDefectMac = () => {
  const intl = useIntl()
  return (
    <Container>
      <Content>
        <TextHolder>
          <Header>{intl.formatMessage(i18n.waterRepair.title)}</Header>
          <Text>
            {intl.formatMessage(i18n.waterRepair.content, {
              color: str => (
                <Link
                  to="/abwicklung-versand"
                  style={{
                    color: "#F4B300",
                    textDecoration: "none",
                    fontWeight: "400",
                  }}
                >
                  {str}{" "}
                </Link>
              ),
            })}
          </Text>
        </TextHolder>
        <ImageHolder>
          <Image source="waterDefect.jpeg" alt="Water defect" />
        </ImageHolder>
      </Content>
    </Container>
  )
}

export default WaterDefectMac
