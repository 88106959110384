import React from "react"
import Page from "../components/common/page"

import { ContentRow } from "../components/common/shared-components/layouts"
import BatteryMac from "../components/page-components/macbook/battery"
import MacbookBullet from "../components/page-components/macbook/bullet-text"
import DisplayDefectMac from "../components/page-components/macbook/display-break"
import DisFailMac from "../components/page-components/macbook/display-fail"
import KeyboardMac from "../components/page-components/macbook/keyboard"
import PanicMac from "../components/page-components/macbook/panic"
import MacPrice from "../components/page-components/macbook/priceList"
import RepairMacbook from "../components/page-components/macbook/repair/repairMac"
import ShotCircuitMac from "../components/page-components/macbook/shot-circuit"
import SloganMac from "../components/page-components/macbook/slogan"
import Start from "../components/page-components/macbook/start"
import WaterDefectMac from "../components/page-components/macbook/water-defect"

const CONTENT_ROWS = [
  [<Start />],
  [<MacbookBullet />],
  [<SloganMac />],
  [<PanicMac />],
  [<RepairMacbook />],
  [<WaterDefectMac />],
  [<DisplayDefectMac />],
  [<DisFailMac />],
  [<ShotCircuitMac />],
  [<BatteryMac />],
  [<KeyboardMac />],
  [<MacPrice />],  
]

const Macbook = () => {
  return (
    <Page title="macbook">
      {CONTENT_ROWS.map((components, key) => (
        <ContentRow key={key} components={components} />
      ))}
    </Page>
  )
}

export default Macbook
