import { defineMessages } from "react-intl"

const scope = "macbook"

export default defineMessages({
  headline: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Sparen Sie bis zu 80% an Reparaturkosten!",
    },
    headline: {
      id: `${scope}.button`,
      defaultMessage: "MacBook-Reparatur",
    },
  },
  diagonose: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Jetzt Schnelldiagnose einholen",
    },
    text1: {
      id: `${scope}.text1`,
      defaultMessage: "Alle Apple Modelle",
    },
    text2: {
      id: `${scope}.text2`,
      defaultMessage: "Keine Aufwandspauschalen",
    },
    text3: {
      id: `${scope}.text3`,
      defaultMessage: "Professionelle Beratung",
    },
  },
  slogan: {
    header: {
      id: `${scope}.header`,
      defaultMessage: "Wo andere aufhören <color>fangen wir an.</color> ",
    },
    middle: {
      id: `${scope}.middle`,
      defaultMessage:
        "Ihr geliebtes MacBook wurde <bold>schrottgeschrieben</bold>, eine Reparatur als <bold>unwirtschaftlich</bold> deklariert oder als <bold>Vintage</bold> eingestuft?",
    },
  },
  panic: {
    header: {
      id: `${scope}.header`,
      defaultMessage: "KEINE PANIK",
    },
    text1: {
      id: `${scope}.text1`,
      defaultMessage: "Sie brauchen <bold>kein</bold> neues MacBook.",
    },
    text2: {
      id: `${scope}.text2`,
      defaultMessage: "WIR KRIEGEN DAS HIN !",
    },
    text3: {
      id: `${scope}.text3`,
      defaultMessage:
        "Die meisten Fehler und Defekte lassen sich schnell und kostengünstig reparieren.",
    },
    text4: {
      id: `${scope}.text4`,
      defaultMessage:
        "Geräte wie das MacBook Pro, das MacBook Air, der Mac mini, der Mac Pro oder der iMac sind technisch höchst anspruchsvoll.<break>Akribie und Fingerspitzengefühl sind bei Reparaturen an Apple-Geräten daher unerlässlich.</break>",
    },
  },
  macbook: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "MacBook-Reparatur vom Profi",
    },
    text1: {
      id: `${scope}.text1`,
      defaultMessage:
        "Wir reparieren Sturzschäden, Kurzschlüsse, Flüssigkeitsschäden und Hardwarefehler Ihres MacBooks schnell und zuverlässig. Gerne stehen wir Ihnen dabei jederzeit beratend zur Seite und informieren Sie über jeden Schritt. ",
    },
    text2: {
      id: `${scope}.text2`,
      defaultMessage:
        "Hierbei erklären wir Ihnen wahlweise die individuellen Abläufe und sorgen so für eine größtmögliche Transparenz unserer Arbeit.",
    },
  },
  displayFail: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Display Fehler",
    },
    text1: {
      id: `${scope}.text1`,
      defaultMessage:
        "Bunte Streifen, Kästchen, Flecken, Pixelfehler oder Hieroglyphen sind vielleicht künstlerisch wertvoll, haben auf dem Display aber nichts verloren. Diese Darstellungsfehler behindern die Arbeit und haben oft einen defekten Grafikchip als Auslöser. ",
    },
    text2: {
      id: `${scope}.text2`,
      defaultMessage:
        " Mithilfe unserer mehr als vierzehnjährigen Erfahrung nehmen wir einen Austausch schnell und kompetent vor. So ist Ihr Gerät in kürzester Zeit wieder einsatzfähig.",
    },
  },
  displayBreak: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Displaybruch",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Ein Bruch oder ein Riss im Display ist gerade bei teuren Apple-Geräten überaus ärgerlich. Der Austausch des Displays ist jedoch schnell und kostengünstig möglich. Wir besitzen einen umfangreichen Bestand an MacBook-Displays und nehmen den Austausch in der Regel innerhalb von <color>24 Stunden</color> vor. <break>Hierbei tauschen wir nur die notwendigen Komponenten aus, um Ihnen Mehrkosten zu ersparen. Wir ersetzen komplette Displays inklusive Cover oder lediglich <color>die Frontpanels.</color></break>",
    },
  },
  waterRepair: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Flüssigkeitsschaden",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Gelangen Wasser, Kaffee oder Saft in das MacBook ist ein schnelles Handeln erforderlich. Schalten Sie das Gerät sofort aus und trennen Sie es vom Netzteil. Nehmen Sie daraufhin umgehend Kontakt mit uns auf. Mit unserem <color>UPS-Express-Abholservice</color> ist das Gerät innerhalb von 24 Stunden bei uns. Wir unterziehen es einer kostenlosen Schnelldiagnose und schlagen Ihnen weitere Handlungsschritte vor.",
    },
  },
  batteryChange: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Einsatz neuer Akkus",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Ihr Akku fällt aus oder lässt bereits nach kurzer Zeit nach? Gerne ersetzen wir Ihren Akku und ermöglichen Ihnen ein lang anhaltendes mobiles Arbeiten an Ihrem Gerät.",
    },
  },
  shotCircuit: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Kurzschluss auf dem Logicboard",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Ein Kurzschluss auf dem Logicboard erfordert nicht automatisch einen Neukauf. Unsere Experten regenerieren die Leiterbahnen auf dem Logicboard, wechseln die beschädigten Bauteile und ersparen Ihnen einen Austausch.",
    },
  },
  keyboard: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Tastaturwechsel",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Manchmal funktionieren einige Tasten auf dem MacBook nicht richtig. In diesem Fall ersetzen wir die Tastatur fachgerecht und unkompliziert. Der Umbau ist auch bei den Modellen aus dem <color>Jahr 2020</color> problemlos möglich. Wir tauschen hierbei nicht die komplette Handablage (Topcase) aus, sondern lediglich die Tastatur selbst. Dadurch ist die Reparatur wesentlich günstiger.",
    },
  },
  insurance: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Für die Versicherung",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Gerne erstellen wir Ihnen einen detaillierten Kostenvoranschlag für Ihre Versicherung.",
    },
  },
  specialRepair: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Spezial Reparaturen",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Cillum nisi pariatur reprehenderit occaecat irure proident sit anim dolore cupidatat aliquip. Ad commodo ullamco amet dolore do aliquip dolore. Laborum exercitation nisi mollit adipisicing exercitation adipisicing exercitation est irure et irure. Et aliquip dolor Lorem amet do est. Et deserunt anim ipsum aliqua aliqua proident ea.",
    },
  },
  imac: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "iMac Reparatur",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Auch iMac's haben so Ihre Krankheiten. Lässt sich Ihr iMac nicht einschalten kann Dies beispielsweise am internen Netzteil liegen. Dieser Defekt ist schnell und kostengünstig zu beheben, doch lassen Sie lieber einen Profi ran. Das Display ist verklebt und lässt sich nur schwer lösen. Sollte Ihnen das gelingen, lauern schon die nächsten Hindernisse direkt dahinter. ",
    },
  },
  price: {
    header: {
      id: `${scope}.header`,
      defaultMessage: "Priceliste",
    },
    normal: {
      fastCheck: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Schnelldiagnose",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: "kostenlos",
        },
      },
      expense: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Aufwandspauschale",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: "kostenlos",
        },
      },
      try: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Kosten bei Misserfolg",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: "kostenlos",
        },
      },
      macbookClean: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Professionelle Macbook Reinigung",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 39,
        },
      },
      insurance: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Kostenvoranschlag für Versicherung",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 39,
        },
      },
      express: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Express 24 Std. Bearbeitung",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 49,
        },
      },
      hardwareChange: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Einsatz neuer Strombuchsen",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 79,
        },
      },
      displayChange: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Einsatz neuer Displays zzgl. Material",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 149,
        },
      },
    },
    komplex: {
      title: {
        id: `${scope}.title`,
        defaultMessage: "Komplexe Reparaturen auf Chiplevel",
      },
      battery: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Reparatur der Ladeelektronik",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 179,
        },
      },
      current: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Reparatur der Stromversorgung",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 199,
        },
      },
      shotcircuit: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Behebung von Kurzschlüssen",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 199,
        },
      },
      WaterDefect: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Flüssigkeitschaden",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 149,
        },
      },
      graphic: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Einsatz neuer Grafikchips",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 249,
        },
      },
    },
    special: {
      title: {
        id: `${scope}.title`,
        defaultMessage: "Spezial Reparaturen",
      },
      bios: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Neue EFI Programmierung",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 119,
        },
      },
      battery: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Einsatz neuer Akkus inkl Material",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 149,
        },
      },
      keyboard: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Einsatz neuer Tastaturen inkl MAterial",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 129,
        },
      },
      disk: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Datenrettung aus Festplatten",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 99,
        },
      },
      board: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Platinen Reparatur von Festplatten",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 149,
        },
      },
      graphicChip: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Grafikchip (GPU) Deaktivierung",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 99,
        },
      },
    },
  },
})
