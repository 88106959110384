import React from "react";
import Slogan from "../../../common/slogan";

import { useIntl } from "gatsby-plugin-react-intl";
import i18n from "../i18n";

const SloganMac = () => {
  const intl = useIntl();
  return (
    <Slogan
      header={intl.formatMessage(i18n.slogan.header, {
        color: (str) => <span style={{ color: "#35C4E6" }}>{str}</span>,
      })}
      middle={intl.formatMessage(i18n.slogan.middle, {
        bold: (str) => <b>{str}</b>,
      })}
      source="repairProfi.jpg"
      alt="repair macbook"
    />
  );
};

export default SloganMac;
