import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  font-family: "Open Sans";
  margin-bottom: 1rem;
`

export const Header = styled.h3`
  width: 100%;
  font-family: "Open Sans";
  text-align: center;
  color: white;
  font-size: 2rem;
  padding: 1.5rem 0;
  @media (max-width: 768px) {
    font-size: 2rem;
    margin: 0;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: black;
  margin-top: -25px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

export const ImageHolder = styled.div`
  width: 50%;

  @media (max-width: 768px) {
    width: 100vw;
  }
`
export const TextHolder = styled.div`
  box-sizing: border-box;
  width: 50%;
  padding: 0 6rem;
  @media (max-width: 768px) {
    width: 100vw;
    padding: 1.5rem;
  }
`

export const Text = styled.p`
  font-family: "Open Sans";
  color: white;
  font-size: 1.5rem;
  font-weight: 300;
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`
