import React from "react"
import styled from "styled-components"
import { Image } from "../../../common/shared-components/images"

import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../i18n"

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  font-family: "Open Sans";
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 1rem 1rem 1rem;
  }
`
const Header = styled.h1`
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 2.5rem;
  color: black;
  margin: 1.5rem auto 3rem auto;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 2rem;
    text-align: center;
    margin: 1.5rem auto;
    line-height: 1.3;
  }
`

const TextHolder = styled.div`
  width: 50%;
  margin: 0 auto;
  padding: 0 0 0 5rem;
  font-size: 1.5rem;
  font-weight: 300;
  @media (max-width: 768px) {
    padding: 0 0.5rem;
    width: 100%;
  }
`

const Holder = styled.div`
  width: 100%;
  background: #d9d9d9;
  margin: 1rem 0;
`

const ImageHolder = styled.div`
  width: 50%;
  border: 1px solid transparent;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100vw;
  }
`
const Text = styled.p`
  font-size: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`

const RepairMacbook = () => {
  const intl = useIntl()
  return (
    <Holder>
      <Container>
        <ImageHolder>
          <Image source="macrepair.jpg" alt="Repair" />
        </ImageHolder>
        <TextHolder>
          <Header>{intl.formatMessage(i18n.macbook.title)} </Header>
          <Text>{intl.formatMessage(i18n.macbook.text1)}</Text>
          <Text>{intl.formatMessage(i18n.macbook.text2)}</Text>
        </TextHolder>
      </Container>
    </Holder>
  )
}

export default RepairMacbook
