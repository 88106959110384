import React from "react"
import styled from "styled-components"
import ImgHolder from "../../../common/image-holder"

import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../i18n"

const Container = styled.div`
  width: 100%;
  background: black;
  text-align: center;
  font-family: "Open Sans";
`
const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 768px) {
    width: 100vw;
  }
`
const Header = styled.h1`
  font-family: "Open Sans";
  color: white;
  font-size: 2rem;
  margin: 1.2rem 0;
  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }
`
const Subtitle = styled.p`
  width: 100%;
  text-align: center;
  color: #5ce1e6;
  font-size: 3rem;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 768px) {
    font-size: 2rem;
    top: 40%;
  }
`

const NotebookCard = () => {
  const intl = useIntl()

  return (
    <Container>
      <Content>
        <Header>{intl.formatMessage(i18n.headline.headline)} </Header>
        <ImgHolder source="macPage.jpeg" alt="Macbook" />
        <Subtitle>{intl.formatMessage(i18n.headline.title)} </Subtitle>
      </Content>
    </Container>
  )
}

export default NotebookCard
