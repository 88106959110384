import styled from "styled-components"

export const Container = styled.div`
  width: 100vw;
  background: #282828;
  padding-bottom: 6rem;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`

export const Header = styled.h3`
  box-sizing: border-box;
  font-family: "Open Sans";
  text-align: center;
  background: #282828;
  margin: 0;
  padding: 1.5rem;
  color: white;
  font-size: 2rem;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 2rem;
  }
`

export const ImgHolder = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  opacity: 0.7;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Text = styled.p`
  font-family: "Open Sans";
  max-width: 1000px;
  margin: 1.5rem auto;
  font-size: 1.5rem;
  font-weight: 300;
  color: white;
  @media (max-width: 768px) {
    font-size: 1.3rem;
    margin: 0.5rem 1.5rem;
  }
`
