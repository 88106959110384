import React from "react";
import { Image } from "../../../common/shared-components/images";
import {
  Container,
  Content,
  Header,
  ImageHolder,
  Text,
  TextHolder,
} from "./styles";

import { useIntl } from "gatsby-plugin-react-intl";
import i18n from "../i18n";

const BatteryMac = () => {
  const intl = useIntl();
  return (
    <Container>
      <Content>
        <ImageHolder>
          <Image source="battery.jpeg" alt="battery change" />
        </ImageHolder>
        <TextHolder>
          <Header>{intl.formatMessage(i18n.batteryChange.title)}</Header>
          <Text>{intl.formatMessage(i18n.batteryChange.content)}</Text>
        </TextHolder>
      </Content>
    </Container>
  );
};

export default BatteryMac;
