import React from "react"
import { Image } from "../../../common/shared-components/images"
import {
  Container,
  Content,
  Header,
  ImageHolder,
  Text,
  TextHolder,
} from "./styles"

import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../i18n"

const DisplayDefectMac = () => {
  const intl = useIntl()
  return (
    <Container>
      <Content>
        <ImageHolder>
          <Image source="displayBreak.jpeg" alt="Display defect" />
        </ImageHolder>
        <TextHolder>
          <Header>{intl.formatMessage(i18n.displayBreak.title)}</Header>
          <Text>
            {intl.formatMessage(i18n.displayBreak.content, {
              color: str => <span style={{ color: "#5CE1E6" }}>{str} </span>,
              break: str => (
                <span>
                  <br />
                  <br />
                  {str}{" "}
                </span>
              ),
            })}
          </Text>
        </TextHolder>
      </Content>
    </Container>
  )
}

export default DisplayDefectMac
